<template>
  <div>
    <div>
      <div class="card-header cardHeader-bg" style="padding: 14px;">
        <h4 class="card-title mb-0">Restaurant Orders</h4>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select>
                entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
        </div>

        <div class="table-responsive mb-0">
          <b-table
            :items="loans"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            ref="selectableTable"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(id)="loans">
              <input
                type="checkbox"
                :value="loans.item.id"
                v-model="checkedNames"
              />
            </template>

            <template v-slot:cell(status)="loans">
              <button
                v-bind:class="changeClass(loans)"
                @click="singlActiveDeactive(loans)"
              >
                {{ changeVerfiedtext(loans) }}</button
              >&nbsp;
            </template>
            <template v-slot:cell(actions)="loans">
              <button
                v-bind:class="'btn btn-success btn-sm'"
                @click="editResturant(loans)"
              >
                Edit</button
              >&nbsp;
              <button
                v-bind:class="'btn btn-danger btn-sm'"
                @click="deleterRecord(loans)"
              >
                Delete</button
              >&nbsp;
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checkedNames: [],
      loans: [],
      isSelectAll: false,
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        { key: "restaurant.name", label: "Restaurant Name", sortable: true },
        { key: "totalprice", label: "Order Price", sortable: true },
        { key: "ordertype", label: "Order Type", sortable: true },
        { key: "order_status", label: "Status", sortable: true },
      ],
    };
  },

  mounted() {
    this.editRestaurant = JSON.parse(localStorage.getItem("editRestaurant"));
    this.loans = this.editRestaurant.orders;
  },

  computed: {
    rows() {
      return this.loans.length;
    },
  },

  methods: {
    confirmationBulkAction(status) {
      this.$emit("confirmationBulkAction", status, this.checkedNames);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    singlActiveDeactive(object) {
      this.$emit("showModal", true, object);
    },

    deleterRecord(object) {
      this.$emit("deleteData", object);
    },

    editResturant(object) {
      localStorage.setItem("editRestaurant", JSON.stringify(object.item));
      this.$emit("editResturant", object);
    },

    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
  },

  watch: {
    updatedIsSelectAll(val) {
      if (val === false) {
        this.checkedNames = [];
        this.isSelectAll = false;
        this.$emit("updateStatus", true);
      }
    },
    isSelectAll: function(val) {
      if (val) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (x) => x.id
        );
      } else {
        this.checkedNames = [];
      }
    },
  },
};
</script>

<style scoped>
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
.btn-info {
  background-color: #697165;
  border-color: #697165;
}
.btn-info:hover {
  border-color: #697165 !important;
  background-color: #697165 !important;
}

.btn-primary {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:hover {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-primary:active {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:active {
  border-color: #697165 !important;
  background-color: #697165 !important;
}

.btn-info:focus {
  border-color: #697165 !important;
  background-color: #697165 !important;
}

.btn-primary:focus {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
</style>
